import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { api } from '../../APIs/api';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import { toast } from 'react-toastify';
// import QueenslandGetInTouch from '../../components/QueenslandComponents/QueenslandGetInTouch';

const GoldcostDetailsPage = () => {
  const location = useLocation();
  const blogSlug = location?.state?.blogSlug;
  const [isLoader, setIsLoader] = useState(true);
  const [blogDetails, setBlogDetails] = useState(null);

  const getQueenslandBlogBySlugApi = async () => {
    try {
      setIsLoader(true);
      const blogListRes = await api.get(
        `${BASE_URL}${API_URL.GET_GOLDCOAST_BLOG_BY_SLUG}/${blogSlug}`
      );
      const { data, message, status } = blogListRes;
      if (status) {
        setBlogDetails(data);
      } else {
        toast.error(message, { autoClose: 2000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 2000 });
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getQueenslandBlogBySlugApi();
  }, []);

  if (isLoader) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!blogDetails) {
    return (
      <Box textAlign="center" mt={5}>
        <Typography variant="h5" color="error">
          Blog not found!
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          height: { xs: '250px', md: '400px', lg: '500px' },
          backgroundImage: `url(${blogDetails.blog_image_url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
      <Container maxWidth="lg">
        <Box mt={4} textAlign="center">
          <Typography
            sx={{
              fontFamily: 'gothamBold',
              fontSize: { xs: 12, sm: 14, md: 20, lg: 24 },
              color: '#000'
            }}>
            {blogDetails.blog_title}
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography
            fontFamily="gothamBook"
            color="#000"
            sx={{
              textAlign: 'justify',
              lineHeight: '1.8',
              wordWrap: 'break-word',
              whiteSpace: 'normal'
            }}
            dangerouslySetInnerHTML={{ __html: blogDetails.blog_description }}
          />
        </Box>
      </Container>
      {/* <QueenslandGetInTouch /> */}
    </Box>
  );
};

export default GoldcostDetailsPage;
