import React, { useEffect, useRef, useState, startTransition } from 'react';
import { api } from '../../APIs/api';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { PhotoData } from '../../constants/QueenslandConstant';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import HelmetComponent from '../../components/MetaDataComponent/Helmet';
// import QueenslandHeader from '../../layout/QueenslandHeader/QueenslandHeader';
import QueenslandHomeBanner from '../../components/QueenslandComponents/QueenslandHomeBanner';
import HomeBannerImg from '../../assets/Images/queensland/Queensland-home-banner.jpg';
import HomeMobileBannerImg from '../../assets/Images/queensland/Queensland-home-mobile-banner.jpg';
import ExploreQueensland from '../../components/QueenslandComponents/ExploreQueenslandPackages/ExploreQueensland';
import QueenslandBlogs from '../../components/QueenslandComponents/QueenslandHolidayIdeas/QueenslandBlogs';
import QueenslandPhotoGallery from '../../components/QueenslandComponents/QueenslandPhotoGallery';
// import QueenslandGetInTouch from '../../components/QueenslandComponents/QueenslandGetInTouch';

const MotionBox = motion.create(Box);
const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
};
const QueenslandHomeMain = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const observer = useRef();
  const customizedHolidayRef = useRef(null);
  const homeMetaData = useSelector((state) => state?.metaDataList?.metaDataList[0]);
  const [isLoader, setIsLoader] = useState(false);
  const [queenslandPackageListData, setQueenslandPackageListData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [queenslandBlogsList, setQueenslandBlogsList] = useState([]);

  const [sectionVisibility, setSectionVisibility] = useState({
    exploreQueensland: false,
    queenslandHolidayIdeas: false,
    photoGallery: false
  });

  // START: Get & Set the choose currency from session storage
  const updateSelectedCurrency = () => {
    const currency = sessionStorage.getItem('selectedCurrency');
    if (currency) setSelectedCurrency(currency);
  };

  useEffect(() => {
    updateSelectedCurrency();
    const interval = setInterval(updateSelectedCurrency, 1000);
    return () => clearInterval(interval);
  }, []);
  // END:  Get & Set the choose currency from session storage

  const getQueenslandPackageListApi = async () => {
    try {
      setIsLoader(true);
      const travclanListRes = await api.get(`${BASE_URL}${API_URL.GET_QUEENSLAND_PACKAGE_LIST}`);
      // console.log('GET_QUEENSLAND_PACKAGE_LIST :', GET_QUEENSLAND_PACKAGE_LIST);
      // console.log('API_URL :', API_URL);
      // console.log('BASE_URL :', BASE_URL);
      const { data, message, status } = travclanListRes;
      if (status) {
        setQueenslandPackageListData(data);
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  const getQueenslandBlogListApi = async () => {
    try {
      setIsLoader(true);
      const blogListRes = await api.get(`${BASE_URL}${API_URL.GET_QUEENSLAND_BLOG_LIST}`);
      const { data, message, status } = blogListRes;
      if (status) {
        setQueenslandBlogsList(data);
      } else {
        toast.error(message, { autoClose: 2000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 2000 });
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.setItem('isQueenslandHeader', true);
    sessionStorage.setItem('isNewSouthWalesHeader', false);
    getQueenslandPackageListApi();
    getQueenslandBlogListApi();
  }, []);

  useEffect(() => {
    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          startTransition(() => {
            setSectionVisibility((prev) => ({
              ...prev,
              [entry.target.id]: true
            }));
          });
          observer.current.unobserve(entry.target);
        }
      });
    };

    observer.current = new IntersectionObserver(handleIntersect, options);
    const sections = ['exploreQueensland', 'queenslandHolidayIdeas', 'photoGallery'];
    sections.forEach((section) => {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        observer.current.observe(sectionElement);
      }
    });
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <>
      <HelmetProvider>
        <HelmetComponent metaData={homeMetaData} />
      </HelmetProvider>
      {/* <QueenslandHeader customizedHolidayRef={customizedHolidayRef} /> */}
      <QueenslandHomeBanner bannerImg={isMobile ? HomeMobileBannerImg : HomeBannerImg} />

      <MotionBox
        id="exploreQueensland"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.exploreQueensland ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.2 }}>
        <ExploreQueensland
          queenslandPackageList={queenslandPackageListData}
          isLoader={isLoader}
          selectedCurrency={selectedCurrency}
          ref={customizedHolidayRef}
        />
      </MotionBox>

      <MotionBox
        id="queenslandHolidayIdeas"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.queenslandHolidayIdeas ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.2 }}>
        <QueenslandBlogs queenslandBlogsList={queenslandBlogsList} isLoader={isLoader} />
      </MotionBox>

      <MotionBox
        id="photoGallery"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.photoGallery ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.2 }}>
        <QueenslandPhotoGallery photos={PhotoData} />
      </MotionBox>
      {/* <QueenslandGetInTouch /> */}
    </>
  );
};

export default QueenslandHomeMain;
