import Photo1 from '../assets/Images/goldcost/photo-1.jpg';
import Photo2 from '../assets/Images/goldcost/photo-2.jpg';
import Photo3 from '../assets/Images/goldcost/photo-3.jpg';
import Photo4 from '../assets/Images/goldcost/photo-4.jpg';
import Photo5 from '../assets/Images/goldcost/photo-5.jpg';
import Photo6 from '../assets/Images/goldcost/photo-6.jpg';
import Photo7 from '../assets/Images/goldcost/photo-7.jpg';
import Photo8 from '../assets/Images/goldcost/photo-8.jpg';
import Photo9 from '../assets/Images/goldcost/photo-9.jpg';

export const PhotoData = [Photo1, Photo2, Photo3, Photo4, Photo5, Photo6, Photo7, Photo8, Photo9];

// export const VideoData = [
//   {
//     title: 'Cairns & Great Barrier Reef',
//     url: 'https://youtube.com/shorts/Cr2S65gAJV8?feature=share'
//   },
//   {
//     title: 'Gold Coast Hinterland Adventure',
//     url: 'https://youtube.com/shorts/iXavRxWHFW8?feature=share'
//   },
//   {
//     title: 'Great Barrier Reef Drive',
//     url: ' https://youtube.com/shorts/Hd7jBky6gd8?feature=share'
//   },
//   {
//     title: 'Discover Barefoot Luxury',
//     url: 'https://youtube.com/shorts/eYmrnkNlKqo?feature=share'
//   }
// ];
