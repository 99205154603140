import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { CalendarMonth } from '@mui/icons-material';
import CarouselHeader from '../../../pages/PackageListing/CarouselHeader';
import { capitalizeFirstLetter, cleanUrlString, formatPrice } from '../../../Helper/global';
import Swal from 'sweetalert2';

const GoldCostPackageCard = ({ tourPackage, isLoader, selectedCurrency }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const normalizedPath = pathname.replace(/\/$/, '');
  const isQueenslandGroupTour = normalizedPath.startsWith('/destination/queensland/group-tours');
  const tourPlaces = [...tourPackage.places].sort((a, b) => a.Place.localeCompare(b.Place));

  const [showMorePlaces, setShowMorePlaces] = useState(false);
  const handleShowMorePlaces = () => setShowMorePlaces(true);
  const visiblePlaces = showMorePlaces ? tourPlaces : tourPlaces.slice(0, 2);
  const remainingPlaces = tourPlaces.slice(2);

  const handleEnquiryNow = () => {
    const countryName = tourPackage?.country;
    const packageId = tourPackage?.new_item_code;
    const numberOfNights = tourPackage?.number_of_nights;

    sessionStorage.setItem(
      'tripDetails',
      JSON.stringify({
        countryName: countryName,
        packageId: packageId,
        numberOfNights: numberOfNights
      })
    );
    navigate(`/destination/gold-coast/${cleanUrlString(tourPackage?.package_name)}/vacation-spot`, {
      state: {
        packageId: packageId,
        selectedTour: countryName
      }
    });
  };

  return (
    <Box
      mb={3}
      width={{ xs: '96%', md: '92%' }}
      boxShadow="10px 10px 30px 0px #37444A29"
      backgroundColor="#FFFFFF"
      borderRadius="10px"
      overflow="hidden">
      <Box width={{ xs: '100%', md: '100%' }} height="170" position="relative">
        <CarouselHeader tourPackage={tourPackage} places={tourPlaces} isLoader={isLoader} />
      </Box>
      <Box>
        <Box px={{ xs: 2.5, md: '12px' }} pb={0} pt={{ xs: '18px', md: '8px' }}>
          <Typography
            fontFamily="gothamBold"
            fontSize={{ xs: 14, md: 20 }}
            lineHeight={{ xs: '22px', md: '32px' }}
            whiteSpace="normal"
            component="h2"
            minHeight={{ xs: '44px', md: '64px' }}>
            {isLoader ? (
              <Skeleton variant="text" width="80%" height={{ xs: 44, md: 64 }} />
            ) : (
              tourPackage?.package_name
            )}
          </Typography>
          {isLoader ? (
            <Skeleton variant="text" width="80%" height={30} />
          ) : (
            <>
              <Box
                display="flex"
                alignItems="center"
                gap={{ xs: '8px', sm: '12px', md: '20px', lg: '20px' }}
                pt="22px">
                <Box display="flex" alignItems="center" gap="6px">
                  <CalendarMonth fontSize="small" />
                  <Typography
                    component="span"
                    fontFamily="gothamBold"
                    fontSize="14px"
                    lineHeight="16px">
                    {tourPackage?.number_of_nights || 0} N /
                  </Typography>
                  <Typography
                    component="span"
                    fontFamily="gothamBold"
                    fontSize="14px"
                    lineHeight="16px">
                    {tourPackage?.number_of_days || 0} D
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box bgcolor="#f9f9fb" pt={0} pr={3} pb={3} pl={3} minWidth="168px">
          {isLoader ? (
            <Skeleton variant="text" width="100%" height={30} />
          ) : (
            <>
              <Box display="flex" flexWrap="wrap" alignItems="center" color="#000000CC" gap="6px">
                <Typography
                  component="span"
                  fontFamily="gothamBook"
                  fontSize="13px"
                  lineHeight="24px">
                  Visit:{' '}
                  {visiblePlaces.map((place) => capitalizeFirstLetter(place.Place)).join(', ')}
                  {!showMorePlaces && remainingPlaces.length > 0 && ' and '}
                </Typography>

                {!showMorePlaces && remainingPlaces.length > 0 && (
                  <Typography
                    component="span"
                    fontFamily="gothamBold"
                    fontSize="13px"
                    lineHeight="24px"
                    color="#27306B"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleShowMorePlaces}>
                    {' '}
                    more
                  </Typography>
                )}
              </Box>
            </>
          )}
          <Box pt={{ xs: '18px', md: '13px' }}>
            <Typography
              component="span"
              fontFamily="gothamBook"
              fontSize={14}
              lineHeight={{ xs: '22px', md: '32px' }}>
              Starting From
            </Typography>
            <Typography
              component="span"
              display="flex"
              fontFamily="gothamBold"
              fontSize={{ xs: 14, md: 20 }}
              lineHeight={{ xs: '22px', md: '32px' }}
              sx={{ color: '#27306B' }}>
              {isLoader ? (
                <Skeleton variant="text" width="80%" height={30} />
              ) : (
                <>
                  {(() => {
                    const currencyMapping = {
                      INR: 'inr_price',
                      USD: 'usd_price',
                      AED: 'aed_price',
                      AUD: 'aud_price',
                      EUR: 'eur_price',
                      GBP: 'gbp_price',
                      SGD: 'sgd_price',
                      NZD: 'nzd_price'
                    };

                    const selectedPrice =
                      tourPackage?.[currencyMapping[selectedCurrency]]?.toFixed(0) ||
                      tourPackage?.inr_price?.toFixed(0);
                    const currencySymbol = selectedCurrency || 'INR';
                    return `${currencySymbol} ${formatPrice(selectedPrice)}/-`;
                  })()}
                </>
              )}
              <Typography
                pl={1}
                component="span"
                fontFamily="gothamBook"
                fontSize={14}
                lineHeight={{ xs: '22px', md: '32px' }}
                sx={{ color: '#000000' }}>
                Per Person
              </Typography>
            </Typography>
          </Box>

          {!isQueenslandGroupTour ? (
            <Box
              className="btn-container"
              gap="10px"
              mt="20px"
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              justifyContent="space-between"
              width="100%">
              <Button
                onClick={handleEnquiryNow}
                sx={{
                  bgcolor: 'transparent',
                  color: '#000000B2',
                  border: '1px solid #0000004D',
                  borderRadius: '6px',
                  fontSize: '14px',
                  fontFamily: 'gothamBook',
                  lineHeight: '28px',
                  padding: '11px 10px',
                  textAlign: 'center',
                  textTransform: 'capitalize',
                  maxHeight: '46px',
                  width: {
                    xs: '45%',
                    sm: '50%',
                    md: '50%',
                    lg: '50%',
                    xl: '50%'
                  },
                  ':hover': {
                    color: '#000000B2',
                    bgcolor: 'transparent',
                    border: '1px solid #0000004D'
                  }
                }}>
                Enquire Now
              </Button>
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(`/destination/gold-coast/${cleanUrlString(tourPackage?.package_name)}`, {
                    state: { packageId: tourPackage?.new_item_code }
                  })
                }
                sx={{
                  bgcolor: '#E41E25',
                  color: '#fff',
                  border: '1px solid #0000004D',
                  borderRadius: '6px',
                  fontFamily: 'gothamBook',
                  fontSize: '14px',
                  lineHeight: '28px',
                  padding: '11px 10px',
                  textAlign: 'center',
                  textTransform: 'capitalize',
                  maxHeight: '46px',
                  width: {
                    xs: '48%',
                    sm: '50%',
                    md: '50%',
                    lg: '50%',
                    xl: '50%'
                  },
                  ':hover': {
                    color: '#fff',
                    bgcolor: '#E41E25'
                  }
                }}>
                View Details
              </Button>
            </Box>
          ) : (
            <Box
              className="btn-container"
              gap="10px"
              mt="20px"
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              justifyContent="center"
              alignItems="center"
              width="100%"
              minHeight="100px">
              {/* <Button
                variant="outlined"
                onClick={() => window.open(tourPackage?.package_url)}
                sx={{
                  bgcolor: '#E41E25',
                  color: '#fff',
                  border: '1px solid #0000004D',
                  borderRadius: '6px',
                  fontFamily: 'gothamBook',
                  fontSize: '14px',
                  lineHeight: '28px',
                  padding: '11px 10px',
                  textAlign: 'center',
                  textTransform: 'capitalize',
                  maxHeight: '46px',
                  width: '100%',
                  ':hover': {
                    color: '#fff',
                    bgcolor: '#E41E25'
                  }
                }}>
                View Details
              </Button> */}
              <Button
                variant="outlined"
                onClick={() => {
                  Swal.fire({
                    text: 'You are being directed to Kesari Tours website for group tours.',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, to proceed.',
                    cancelButtonText: 'No, stay on this website',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                  }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.cancel) {
                      window.location.replace(
                        'https://www.kesariselect.com/destination/queensland'
                      );
                    } else {
                      if (tourPackage?.package_url) {
                        window.open(tourPackage.package_url, '_blank');
                      }
                    }
                  });
                }}
                sx={{
                  bgcolor: '#E41E25',
                  color: '#fff',
                  border: '1px solid #0000004D',
                  borderRadius: '6px',
                  fontFamily: 'gothamBook',
                  fontSize: '14px',
                  lineHeight: '28px',
                  padding: '11px 10px',
                  textAlign: 'center',
                  textTransform: 'capitalize',
                  maxHeight: '46px',
                  // width: '100%',
                  width: { xs: '100%', sm: '48%' }, // Full width on mobile, 48% on larger screens
                  ':hover': {
                    color: '#fff',
                    bgcolor: '#E41E25'
                  }
                }}>
                View Details
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GoldCostPackageCard;
