/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const GoldcostHomeBanner = ({ bannerImg, isLoader }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const normalizedPath = location?.pathname.replace(/\/$/, '');
  const isQueensland = normalizedPath.startsWith('/destination/queensland');
  const isExactQueensland = normalizedPath === '/destination/queensland';

  const handlePlanHolidayClick = () => {
    if (isQueensland && isExactQueensland) {
      navigate('/destination/queensland/packages');
    }
  };

  return (
    <Box
      className="slider-container"
      sx={{ maxHeight: { xs: 252, sm: 500, md: 470, lg: 639 }, content: '""' }}>
      {isLoader ? (
        <Skeleton
          variant="rectangular"
          sx={{
            width: { xs: '100%', sm: '100%', md: 1000, lg: 1399, xl: 1900 },
            height: { xs: 252, sm: 500, md: 470, lg: 639 }
          }}
        />
      ) : (
        <>
          <img
            src={bannerImg}
            alt="Queensland Banner"
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: '639px',
              objectFit: 'cover'
            }}
          />
          {/* {isQueensland && isExactQueensland && (
//             <Box
//               className="Plan-btn"
//               sx={{
//                 position: 'absolute',
//                 bottom: { xs: 530, sm: 40, md: -80, lg: -120 },
//                 right: { xs: 20, sm: 50, md: 80, lg: 100 },
//                 textAlign: 'right',
//                 zIndex: 1000
//               }}>
//               <Button
//                 variant="contained"
//                 onClick={handlePlanHolidayClick}
//                 sx={{
//                   marginTop: 2,
//                   backgroundColor: '#ffffff',
//                   color: '#27306b',
//                   fontSize: { xs: '12px', sm: '16px' },
//                   fontFamily: 'gothamBook',
//                   padding: { xs: '5px 10px', sm: '10px 20px' },
//                   borderRadius: '50px',
//                   textTransform: 'none',
//                   '&:hover': {
//                     backgroundColor: '#e0e0e0'
//                   }
//                 }}
//                 endIcon={
//                   <Box
//                     sx={{
//                       width: 24,
//                       height: 24,
//                       borderRadius: '50%',
//                       backgroundColor: '#27306b',
//                       display: 'flex',
//                       justifyContent: 'center',
//                       alignItems: 'center'
//                     }}>
//                     <ArrowForwardIcon sx={{ fontSize: 16, color: '#fff' }} />
//                   </Box>
//                 }>
//                 Plan Your Holiday
//               </Button>
//             </Box>
//           )} */}
        </>
      )}
    </Box>
  );
};

export default React.memo(GoldcostHomeBanner);
