import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Skeleton } from '@mui/material';
import { cleanUrlString } from '../../../Helper/global';

const BlogCardCarouselHeader = ({ blogData, isLoader }) => {
  const navigate = useNavigate();

  return (
    <Box position="relative">
      {isLoader ? (
        <Skeleton
          variant="text"
          width="100%"
          height={200}
          maxWidth={{ xs: '100%', md: 424 }}
          maxHeight={170}
        />
      ) : (
        <>
          {[].concat(blogData).map((item, index) => (
            <Box
              key={index}
              component="img"
              src={item?.blog_image_url}
              alt={`Image ${index + 1}`}
              maxWidth={{ xs: '100%', md: 424 }}
              maxHeight={170}
              width="100%"
              height="100%"
              sx={{ objectPosition: '0 65%', cursor: 'pointer' }}
              onClick={() =>
                navigate(`/destination/gold-coast/blog/${cleanUrlString(item?.blog_slug)}`, {
                  state: { blogSlug: item?.blog_slug }
                })
              }
            />
          ))}
        </>
      )}
    </Box>
  );
};

export default BlogCardCarouselHeader;
