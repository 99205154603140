import React, { useState } from 'react';
import {
  Box,
  Card,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../common/Button';

const GoldcostPhotoGallery = ({ photos }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedImage, setSelectedImage] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const isGoldcostPhotoPage = location?.pathname === '/destination/gold-coast/photo-gallery';

  const handleImageClick = (src) => {
    setSelectedImage(process.env.PUBLIC_URL + src);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: '1900px',
        margin: '0 auto',
        overflow: 'hidden',
        px: { xs: 2, sm: 4, md: 6 },
        mb: 2
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 2 }}>
        <Box sx={{ flex: 1, height: '2px', backgroundColor: '#000', mx: 2 }} />
        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '18px', md: '22px' },
            fontFamily: 'gothamBold',
            textAlign: 'center',
            color: '#27306B'
          }}>
          PHOTO GALLERY
        </Typography>
        <Box sx={{ flex: 1, height: '2px', backgroundColor: '#000', mx: 2 }} />
      </Box>
      <Box
        sx={{
          overflowX: isSmallScreen ? 'auto' : 'hidden',
          display: 'flex',
          p: 2
        }}>
        <Grid
          container
          spacing={2}
          sx={{
            flexWrap: isSmallScreen ? 'nowrap' : 'wrap',
            justifyContent: 'center',
            mx: 'auto'
          }}>
          {(isGoldcostPhotoPage ? photos : photos.slice(0, 6)).map((src, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 2
              }}>
              <Card
                sx={{
                  borderRadius: '10px',
                  overflow: 'hidden',
                  width: isSmallScreen ? '250px' : isMediumScreen ? '280px' : '320px',
                  height: isSmallScreen ? '220px' : '280px'
                }}>
                <CardMedia
                  component="img"
                  height="280"
                  image={process.env.PUBLIC_URL + src}
                  alt={`Gallery Image ${index + 1}`}
                  onClick={() => handleImageClick(src)}
                  sx={{
                    cursor: 'pointer',
                    objectFit: 'cover',
                    borderRadius: '10px',
                    width: '100%',
                    height: '100%'
                  }}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {!isGoldcostPhotoPage && (
        <Box className="everyStep_btn_container" sx={{ paddingTop: 0, marginBottom: '20px' }}>
          <CustomButton
            className="everyStep_typo_btn"
            onClick={() => navigate('/destination/gold-coast/photo-gallery')}
            showArrow={true}>
            <Typography fontFamily="gothamBold">View All Photos</Typography>
          </CustomButton>
        </Box>
      )}

      {/* Image Modal */}
      <Dialog open={!!selectedImage} onClose={handleClose} maxWidth="md">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            overflow: 'hidden'
          }}>
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              backgroundColor: 'rgba(0,0,0,0.5)',
              color: 'white',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' }
            }}>
            <CloseIcon />
          </IconButton>
          {selectedImage && (
            <Card
              sx={{
                overflow: 'hidden',
                maxWidth: '90vw',
                maxHeight: '90vh'
              }}>
              <CardMedia
                component="img"
                image={selectedImage}
                alt="Selected"
                sx={{
                  width: '100%',
                  maxHeight: '90vh',
                  objectFit: 'contain'
                }}
              />
            </Card>
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default GoldcostPhotoGallery;
